export const data = {
	logoSrc: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/logo.png",
	menus:[{
		name: "首页",
		index: "indexnew",
		url: "/"
	},
	{
		name: "影片案例",
		index: "casesv2",
		url: "/casesv2"
	},
	{
		name: "合作流程",
		index: "aboutv2",
		url: "/aboutv2"
	},
	{
		name: "联系我们",
		index: "concatv2",
		url: "/concatv2"
	}],
	testDataList: [{
		id: "1",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%9C%B0%E9%93%81%E6%96%B0%E7%BA%BF%E5%8D%A1%E9%80%9A%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/animated_drawing_01.png",
		imgAlt: "动画应用领域",
		title: "地铁新线卡通动画",
		memo: " 地铁新线卡通动画。"
	}, {
		id: "2",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%A4%A9%E6%B5%B7%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/animated_drawing_02.png",
		imgAlt: "动画应用领域",
		title: "天海城市花园三维动画 ",
		memo: "天海城市花园三维动画。"
	}, {
		id: "3",
		type: "其他3D动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%B9%B8%E7%A6%8F%E9%BE%99%E6%B9%96%E5%B0%8F%E9%95%87%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_01.png",
		imgAlt: "中压开关柜演示",
		title: "幸福龙湖小镇三维动画",
		memo: "幸福龙湖小镇三维动画"
	}, {
		id: "4",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E7%8E%96%E7%8E%BA%E5%8F%B0%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_02.png",
		imgAlt: "动画公司",
		title: "玖玺台三维动画 ",
		memo: "玖玺台三维动画。"
	}, {
		id: "5",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E9%83%B4%E5%B7%9E%E9%95%BF%E5%8D%B7%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_03.png",
		imgAlt: "动画公司",
		title: "郴州长卷三维动画 ",
		memo: "郴州长卷三维动画。"
	}],
	proDataList: [{
		id: "1",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%9C%B0%E9%93%81%E6%96%B0%E7%BA%BF%E5%8D%A1%E9%80%9A%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/animated_drawing_01.png",
		imgAlt: "动画应用领域",
		title: "地铁新线卡通动画",
		memo: " 地铁新线卡通动画。"
	}, {
		id: "2",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%A4%A9%E6%B5%B7%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/animated_drawing_02.png",
		imgAlt: "动画应用领域",
		title: "天海城市花园三维动画 ",
		memo: "天海城市花园三维动画。"
	} ],
	pro2DataList: [{
		id: "101",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%9C%B0%E9%93%81%E6%96%B0%E7%BA%BF%E5%8D%A1%E9%80%9A%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/construction_animation_01.png",
		imgAlt: "动画应用领域",
		title: "地铁新线卡通动画",
		memo: " 地铁新线卡通动画。"
	}, {
		id: "102",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%A4%A9%E6%B5%B7%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/construction_animation_02.png",
		imgAlt: "动画应用领域",
		title: "天海城市花园三维动画 ",
		memo: "天海城市花园三维动画。"
	} ],
	pro4DataList: [{
		id: "1001",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%9C%B0%E9%93%81%E6%96%B0%E7%BA%BF%E5%8D%A1%E9%80%9A%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_01.png",
		imgAlt: "动画应用领域",
		title: "地铁新线卡通动画",
		memo: " 地铁新线卡通动画。"
	}, {
		id: "1002",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%A4%A9%E6%B5%B7%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_02.png",
		imgAlt: "动画应用领域",
		title: "天海城市花园三维动画 ",
		memo: "天海城市花园三维动画。"
	}, {
		id: "1003",
		type: "其他3D动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%B9%B8%E7%A6%8F%E9%BE%99%E6%B9%96%E5%B0%8F%E9%95%87%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_03.png",
		imgAlt: "中压开关柜演示",
		title: "幸福龙湖小镇三维动画",
		memo: "幸福龙湖小镇三维动画"
	},{
		id: "1004",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%9C%B0%E9%93%81%E6%96%B0%E7%BA%BF%E5%8D%A1%E9%80%9A%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_04.png",
		imgAlt: "动画应用领域",
		title: "地铁新线卡通动画",
		memo: " 地铁新线卡通动画。"
	}, {
		id: "1005",
		type: "工业设备动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%A4%A9%E6%B5%B7%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_05.png",
		imgAlt: "动画应用领域",
		title: "天海城市花园三维动画 ",
		memo: "天海城市花园三维动画。"
	}, {
		id: "1006",
		type: "其他3D动画",
		video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%B9%B8%E7%A6%8F%E9%BE%99%E6%B9%96%E5%B0%8F%E9%95%87%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
		img: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/building_drawing_06.png",
		imgAlt: "中压开关柜演示",
		title: "幸福龙湖小镇三维动画",
		memo: "幸福龙湖小镇三维动画"
	} ],
	aboutSrc: require("@/assets/img/v2/workflow.png"),
	caseStr: require("@/assets/img/v2/casev2.png"),
	concatStr: ""
};