<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>

      <div class="main">
          <div class="container video" id="video">
              <div class="video-title">
                  新闻资讯
              </div>
              <div class="tab_box">
                  <div class="det-content">
                      <div class="det-title">{{newDet.title}}</div>
                  </div>
                  <div class="det-date">发布时间：{{newDet.publishDate}}</div>
                  <div v-html="newDet.detHtml"></div>
              </div>
          </div>
      </div>

      <!-- 底部 -->
      <v-footer></v-footer>

  </div>
</template>

<script>

import {data} from "../assets/js/data.js";

import VHeader from "../components/header.vue";
import VFooter from "../components/footer.vue";
export default {
    name: "newsDet",
    components: {
        VHeader,VFooter
    },
    data()
    {
        return {
            newDet: {}
        }
    },
    created() {
        if(this.$route.params.id){
            this.$router.go(-1);
        }

        if(data.dataNewsList){
            this.newDet = data.dataNewsList.find(item => item.id == this.$route.query.id);
        }
    },
    methods: {
        jump(url){
            this.$router.push(url);
        }
    }
}
</script>

<style scoped>
@import "../../src/assets/css/common.css";
@import "../../src/assets/css/news.css";
</style>
