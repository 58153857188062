<template>
	<div class="footer">
		<div class="footer-content">
			<div class="footer-row">
				<div class="footer-text">
					深圳市银溪谷数码传媒有限公司 粤ICP备15072419号 Copyright © Shenzhen Yinxigu of digital technology Co.,Ltd
				</div>
			</div>
			<div class="footer-row">
				<div class="footer-text">公司地址：深圳市南山区科技园-中区M-10栋6C E-mail：858999801@qq.com </div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Footer",
	data() {
		return {

		}
	},

	mounted() {
		let _this = this;
		_this.$nextTick(function () {
			window.addEventListener("scroll", _this.handleScroll);
		});
	},

	methods: {

	},
};
</script>
<style scoped>
.footer {
	border-top: 1px solid #eee;
	text-align: center;
}

.footer-content {
	width: 87.5rem;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	justify-content: center;
}
.footer-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	line-height: 2.5rem;
}
.footer-text {
	white-space: pre-wrap;
}
.footer-link {
	font-size: 13px;
	color: #666;
	white-space: pre-wrap;
}
.footer-strong {
	font-weight: 700;
	white-space: pre-wrap;
}

</style>
