<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>

      <div class="main">
          <div class="container video" id="video">
              <div class="video-title">
                  新闻资讯
              </div>
              <div class="tab_box">
                  <ul class="ul-block">
                      <li class="li-block" v-for="(item,index) in dataNewsList" :key="index" @click="jumpDet(item)">
                          <a class="pic" href="javascript:;" data-title="item.title" :data-value="item.video"
                             @click="palyVideo(item.video)">
                              <img :src="item.img" alt="" />
                          </a>
                          <div class="intro-block">
                              <div class="intro-block-title">{{item.title}}</div>
                              <div class="intro-block-memo" :title="item.memo">{{item.memo}}</div>
                          </div>
                      </li>
                  </ul>
              </div>

              <div class="block pagination">
                  <el-pagination
                      background
                      :page-size="PageSize"
                      @current-change="changeCurrentPage"
                      layout="prev, pager, next"
                      :total="totalLength">
                  </el-pagination>
              </div>
          </div>
      </div>

      <!-- 底部 -->
      <v-footer></v-footer>

  </div>
</template>

<script>

import {data} from "../assets/js/data.js";

import VHeader from "../components/header.vue";
import VFooter from "../components/footer.vue";
export default {
    name: "news",
    components: {
        VHeader,VFooter
    },
    data()
    {
        return {
            PageSize : 2,
            totalLength: 0,
            schemeSrc: data.schemeSrc,
            dataNewsList: []
        }
    },
    created() {
        if(data.dataNewsList){
            this.dataNewsList = data.dataNewsList.slice(0, this.PageSize);
            this.totalLength = data.dataNewsList.length;
        }
    },
    methods: {
        jump(url){
            this.$router.push(url);
        },
        jumpDet(item){
            this.$router.push('newsDet?id='+item.id);
        },
        changeCurrentPage(pageNumber){
            console.log(pageNumber);
            console.log('11');
            this.dataNewsList = data.dataNewsList.slice((pageNumber-1)*this.PageSize, pageNumber*this.PageSize);
        }
    }
}
</script>

<style scoped>
@import "../../src/assets/css/common.css";
@import "../../src/assets/css/news.css";
</style>
