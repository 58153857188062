<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>

      <div class="main">
          <img :src="caseStr" alt="">
      </div>

  </div>
</template>

<script>

import {data} from "../../assets/js/datav2.js";

import VHeader from "../../components/headerv2.vue";

export default {
    name: "casesv2",
    components: {
        VHeader
    },
    data()
    {
        return {
            caseStr: data.caseStr
        }
    },
    methods: {
        jump(url){
            this.$router.push(url);
        }
    }
}
</script>

<style scoped>
@import "../../../src/assets/css/homev2.css";
</style>
