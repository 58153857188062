export const data = {
    logoSrc: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/logo.png",
    menus:[
        {
            name: "影片案例",
            index: "cases",
            url: "",
            subMenues: [{
                name: "3D动画",
                index: "3d",
                url: "/?mount=3d"
            },{
                name: "2D动画",
                index: "2d",
                url: "/?mount=2d"
            },{
                name: "宣传片拍摄",
                index: "advertising",
                url: "/?mount=about"
            },{
                name: "特惠视频",
                index: "favourable",
                url: "/?mount=video"
            }]
        },
        {
            name: "企业历程",
            index: "about",
            url: "/about"
        },
        {
            name: "合作客户",
            index: "customer",
            url: "/customer"
        },
        {
            name: "合作流程",
            index: "process",
            url: "/process"
        },
        {
            name: "新闻资讯",
            index: "news",
            url: "/news"
        },
        {
            name: "联系我们",
            index: "concat",
            url: "/concat"
        }
    ],
    tabs3d: [{
        title: '全部',
        name: '全部',
        content: '全部'
    },{
        title: '工业设备动画',
        name: '工业设备动画',
        content: '工业设备动画'
    },{
        title: '产品动画',
        name: '产品动画',
        content: '产品动画'
    },{
        title: '生产线动画',
        name: '生产线动画',
        content: '生产线动画'
    },{
        title: '医疗动画',
        name: '医疗动画',
        content: '医疗动画'
    },{
        title: '地产动画',
        name: '地产动画',
        content: '地产动画'
    },{
        title: '其他3D动画',
        name: '其他3D动画',
        content: '其他3D动画'
    }],
    data3dList: [{
        id: "1",
        type: "工业设备动画",
        video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%9C%B0%E9%93%81%E6%96%B0%E7%BA%BF%E5%8D%A1%E9%80%9A%E5%8A%A8%E7%94%BB.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-23062511150K12.jpg",
        imgAlt: "动画应用领域",
        title: "地铁新线卡通动画",
        memo: " 地铁新线卡通动画。"
    }, {
        id: "2",
        type: "工业设备动画",
        video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%A4%A9%E6%B5%B7%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-230625111421637.jpg",
        imgAlt: "动画应用领域",
        title: "天海城市花园三维动画 ",
        memo: "天海城市花园三维动画。"
    }, {
        id: "3",
        type: "其他3D动画",
        video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E5%B9%B8%E7%A6%8F%E9%BE%99%E6%B9%96%E5%B0%8F%E9%95%87%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230320/1-230320105635643.jpg",
        imgAlt: "中压开关柜演示",
        title: "幸福龙湖小镇三维动画",
        memo: "幸福龙湖小镇三维动画"
    }, {
        id: "4",
        type: "工业设备动画",
        video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E7%8E%96%E7%8E%BA%E5%8F%B0%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "玖玺台三维动画 ",
        memo: "玖玺台三维动画。"
    }, {
        id: "5",
        type: "工业设备动画",
        video: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/%E9%83%B4%E5%B7%9E%E9%95%BF%E5%8D%B7%E4%B8%89%E7%BB%B4%E5%8A%A8%E7%94%BB.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "郴州长卷三维动画 ",
        memo: "郴州长卷三维动画。"
    }],
    tabs2d: [{
        title: '全部',
        name: '全部',
        content: '全部'
    },{
        title: 'APP',
        name: 'APP',
        content: 'APP'
    },{
        title: '互联网平台',
        name: '互联网平台',
        content: '互联网平台'
    },{
        title: '产品',
        name: '产品',
        content: '产品'
    },{
        title: '智能系统',
        name: '智能系统',
        content: '智能系统'
    },{
        title: '旅游餐饮',
        name: '旅游餐饮',
        content: '旅游餐饮'
    },{
        title: '企业宣传',
        name: '企业宣传',
        content: '企业宣传'
    },{
        title: '政府政策',
        name: '政府政策',
        content: '政府政策'
    },{
        title: '其他2D',
        name: '其他2D',
        content: '其他2D'
    }],
    data2dList: [{
        id: "1",
        type: "互联网平台",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E5%AE%B6%E5%BA%AD%E5%82%A8%E8%83%BD%E7%94%B5%E6%B1%A0%E5%AE%A3%E4%BC%A0%E7%89%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-23062511150K12.jpg",
        imgAlt: "动画应用领域",
        title: "家庭储能动画宣传片",
        memo: " 家庭储能电池动画演示。"
    }, {
        id: "2",
        type: "互联网平台",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E6%A0%B7%E6%9C%AC%E5%89%8D%E5%8C%BB%E7%96%97%E6%A3%80%E6%B5%8B%E8%AE%BE%E5%A4%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-230625111421637.jpg",
        imgAlt: "动画应用领域",
        title: "样本前检测仪宣传片 ",
        memo: "医疗检测仪器动画演示。"
    }, {
        id: "3",
        type: "互联网平台",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/2023/%E4%B8%AD%E5%8E%8B%E5%BC%80%E5%85%B3%E6%9F%9C%E5%B7%A5%E4%B8%9A.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230320/1-230320105635643.jpg",
        imgAlt: "中压开关柜演示",
        title: "中压开关柜演示",
        memo: "中压电器开关柜，相对于实拍，结构和操作演示得非常清晰。"
    }, {
        id: "4",
        type: "互联网平台",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"
    }, {
        id: "5",
        type: "互联网平台",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"
    }],
    tabsAbout: [{
        title: '全部',
        name: '全部',
        content: '全部'
    },{
        title: '企业宣传',
        name: '企业宣传',
        content: '企业宣传'
    },{
        title: '产品广告',
        name: '产品广告',
        content: '产品广告'
    },{
        title: '餐饮文化',
        name: '餐饮文化',
        content: '餐饮文化'
    },{
        title: '其他拍摄',
        name: '其他拍摄',
        content: '其他拍摄'
    }],
    dataAboutList: [{
        id: "1",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E5%AE%B6%E5%BA%AD%E5%82%A8%E8%83%BD%E7%94%B5%E6%B1%A0%E5%AE%A3%E4%BC%A0%E7%89%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-23062511150K12.jpg",
        imgAlt: "动画应用领域",
        title: "家庭储能动画宣传片",
        memo: " 家庭储能电池动画演示。"
    }, {
        id: "2",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E6%A0%B7%E6%9C%AC%E5%89%8D%E5%8C%BB%E7%96%97%E6%A3%80%E6%B5%8B%E8%AE%BE%E5%A4%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-230625111421637.jpg",
        imgAlt: "动画应用领域",
        title: "样本前检测仪宣传片 ",
        memo: "医疗检测仪器动画演示。"
    }, {
        id: "3",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/2023/%E4%B8%AD%E5%8E%8B%E5%BC%80%E5%85%B3%E6%9F%9C%E5%B7%A5%E4%B8%9A.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230320/1-230320105635643.jpg",
        imgAlt: "中压开关柜演示",
        title: "中压开关柜演示",
        memo: "中压电器开关柜，相对于实拍，结构和操作演示得非常清晰。"
    }, {
        id: "4",
        type: "企业宣传",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"
    }, {
        id: "5",
        type: "企业宣传",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"
    }],
    dataVideoList: [{
        id: "1",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E5%AE%B6%E5%BA%AD%E5%82%A8%E8%83%BD%E7%94%B5%E6%B1%A0%E5%AE%A3%E4%BC%A0%E7%89%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-23062511150K12.jpg",
        imgAlt: "动画应用领域",
        title: "家庭储能动画宣传片",
        memo: " 家庭储能电池动画演示。"
    }, {
        id: "2",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E6%A0%B7%E6%9C%AC%E5%89%8D%E5%8C%BB%E7%96%97%E6%A3%80%E6%B5%8B%E8%AE%BE%E5%A4%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-230625111421637.jpg",
        imgAlt: "动画应用领域",
        title: "样本前检测仪宣传片 ",
        memo: "医疗检测仪器动画演示。"
    }, {
        id: "3",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/2023/%E4%B8%AD%E5%8E%8B%E5%BC%80%E5%85%B3%E6%9F%9C%E5%B7%A5%E4%B8%9A.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230320/1-230320105635643.jpg",
        imgAlt: "中压开关柜演示",
        title: "中压开关柜演示",
        memo: "中压电器开关柜，相对于实拍，结构和操作演示得非常清晰。"
    }, {
        id: "4",
        type: "企业宣传",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"
    }, {
        id: "5",
        type: "企业宣传",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"
    }],

    schemeSrc: "http://www.3wys.com/images/fangan.jpg",
    aboutSrc: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/company_business.png",
    customerSrc: "http://www.3wys.com/images/113.jpg",
    processSrc: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/pay_process.png",

    concatSrc: "https://videosite2024.oss-cn-shenzhen.aliyuncs.com/material/concat_us.png",

    dataNewsList: [{
        id: "1",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E5%AE%B6%E5%BA%AD%E5%82%A8%E8%83%BD%E7%94%B5%E6%B1%A0%E5%AE%A3%E4%BC%A0%E7%89%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-23062511150K12.jpg",
        imgAlt: "动画应用领域",
        title: "家庭储能动画宣传片",
        memo: " 家庭储能电池动画演示。",
        publishDate: '2023-04-17',
        detHtml: "<p><span style=\"font-size: 20px;\">1、产品三维动画宣传片的内容和作用。产品动画主要采用三维动画技术，包括机械结构、特点、功能、工作原理、使用方法、注意事项等。通过三维动画演示，已广泛应用于企业产品研发、测试、宣传、展示等领域，成为企业宣传产品、拓展市场的重要工具。</span></p><p><span style=\"font-size: 20px;\">2、产品三维动画宣传片主要应用于工业动画、机械动画、科技产品动画、设备动画等。可以帮助企业让用户了解原理、结构、功能等，比较常见的产品演示动画是连杆机构的机械动画，利用三维动画效果向客户展示。</span></p>"
    }, {
        id: "2",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/20232/%E6%A0%B7%E6%9C%AC%E5%89%8D%E5%8C%BB%E7%96%97%E6%A3%80%E6%B5%8B%E8%AE%BE%E5%A4%87~1.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230625/1-230625111421637.jpg",
        imgAlt: "动画应用领域",
        title: "样本前检测仪宣传片 ",
        memo: "医疗检测仪器动画演示。",
        publishDate: '2023-04-18',
        detHtml: "样本前检测仪宣传片1111test"

    }, {
        id: "3",
        type: "企业宣传",
        video: "https://yinxigu.oss-cn-shanghai.aliyuncs.com/2023/%E4%B8%AD%E5%8E%8B%E5%BC%80%E5%85%B3%E6%9F%9C%E5%B7%A5%E4%B8%9A.mp4",
        img: "http://www.3wys.cn/uploads/allimg/20230320/1-230320105635643.jpg",
        imgAlt: "中压开关柜演示",
        title: "中压开关柜演示",
        memo: "中压电器开关柜，相对于实拍，结构和操作演示得非常清晰。",
        publishDate: '2023-04-19',
        detHtml: "中压开关柜演示tttt"

    }, {
        id: "4",
        type: "企业宣传",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。",
        publishDate: '2023-04-11',
        detHtml: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力---------------test"

    }, {
        id: "5",
        type: "企业宣传",
        video: "http://3wys.cn/osf/agv%E7%BD%91%E7%AB%99%E7%94%A8.mp4",
        img: "http://www.3wys.com/images/1-2105231139150-L.jpg",
        imgAlt: "动画公司",
        title: "AGV仓储物流机器人宣传片 ",
        memo: "影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。",
        publishDate: '2023-04-12',
        detHtml: " test 1   影片展示了仓储物流机器人几种典型运用环节，凸显了机器人独立工作和人机协同工作的出色能力。"

    }],


    concatHtml: ""
};
