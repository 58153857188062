<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>

      <div class="main">
		<div class="concat_us">
			<div class="content">
				<div class="team">
					
				</div>
				<div class="phone">
					
				</div>
				<div class="mail">
					
				</div>
			</div>
			
		</div>
      </div>


  </div>
</template>

<script>

import {data} from "../../assets/js/datav2.js";

import VHeader from "../../components/headerv2.vue";


export default {
    name: "concatv2",
    components: {
        VHeader
    },
    data()
    {
        return {
            concatStr: data.concatStr
        }
    },
    methods: {
        jump(url){
            this.$router.push(url);
        }
    }
}
</script>

<style scoped>
@import "../../../src/assets/css/homev2.css";
</style>

<style scoped>
.concat_us {
	width: 87.5rem;
	height: 59.125rem;
	background: url('../../assets/img/v2/concat_us_bg.png') 100% no-repeat;
	background-size: 100% 100%;
	
	background-position-y: -69px;
}
.content {
	/* margin-top: 6.375rem; */
	margin: 6.375rem auto 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.team {
	width: 21.375rem;
	height: 18.75rem;
	background: url('../../assets/img/v2/team.png') 100% no-repeat;
	background-size: 100% 100%;
}
.phone {
	width: 21.375rem;
	height: 18.75rem;
	background: url('../../assets/img/v2/phone.png') 100% no-repeat;
	background-size: 100% 100%;
}
.mail {
	width: 21.375rem;
	height: 18.75rem;
	background: url('../../assets/img/v2/email.png') 100% no-repeat;
	background-size: 100% 100%;
}
</style>
