<template>
	<div class="home">
		<!-- 头部 -->
		<v-header @jumpTo="jump"></v-header>
		
		<!-- 内容 -->
		<div class="product1">
			<div class="pro1_content">
				<div class="pro1_content_left">
					<div class="pro1_content_left_1">
						<span>12+年从业经验<br />100+真实案例</span>
					</div>
					<div class="pro1_content_left_2" @click="jump('concatv2')">
						<span>了解更多</span>
						<img src="https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/arrow_right_white.jpg"/>
					</div>
				</div>
				<div class="pro1_content_right">
					<img src="https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/pro1_content_right.jpg"/>
				</div>
			</div>
		</div>
		
		<div class="product2">
			<div class="pro2_content">
				<div class="pro2_left" @click="palyVideo(production1.video)">
					<div class="pro2_left_pic" :style=" { backgroundImage: 'url(' + bgImage1 + ') ', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }">
						<!-- <div class="pro2_left_pay"></div> -->
						<img src="https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/play.jpg" style="width:120px;height:120px;" />
					</div>
				</div>
				<div class="pro2_right">
					<div class="pro2_right_top">
						<div class="pro2_right_title">
							<span>
								真实案例展示-机械动画
							</span>
						</div>
						<div class="pro2_right_subtitle">
							<span>
								沟通是成功的桥梁
							</span>						
						</div>
						<div class="pro2_right_memo">
							<span>
								专业从事3D/视频制作研发
							</span>
						</div>
					</div>
					<div class="pro2_right_swipper">
						<swiper class="swiper" ref="swiper1" :options="swiperOption1">							
							<swiper-slide v-for="(tdata,tindex) in proDataList" :data-index="tindex" :key="tdata.id">
								<img :src="tdata.img" width="100%" @click="palyVideo(tdata.viedo)"/>
								<!-- {{tdata.img}} -- {{tindex}} -->
							</swiper-slide>
							<div class="swiper-button-prev swiper-button-prev_1" slot="button-prev"></div>
							<div class="swiper-button-next swiper-button-next_1" slot="button-next"></div>
						</swiper>
					</div>
				</div>
			</div>
		</div>
		
		<div class="product3">
			<div class="pro3_content">
				<div class="pro3_left">
					<div class="pro3_left_top">
						<div class="pro3_left_title">
							<span>
								真实案例展示-施工可视化
							</span>
						</div>
						<div class="pro3_left_subtitle">
							<span>
								沟通是成功的桥梁
							</span>						
						</div>
						<div class="pro3_left_memo">
							<span>
								专业从事3D/视频制作研发
							</span>
						</div>
					</div>
					<div class="pro3_left_swipper">
						<swiper class="swiper" ref="swiper2" :options="swiperOption2">
							<swiper-slide v-for="(tdata,tindex) in pro2DataList" :data-index="tindex" :key="tdata.id">
								<img :src="tdata.img" width="100%" @click="palyVideo(tdata.viedo)"/>
							</swiper-slide>
							<div class="swiper-button-prev swiper-button-prev_2" data-swiper="swiper2" slot="button-prev"></div>
							<div class="swiper-button-next swiper-button-next_2" data-swiper="swiper2" slot="button-next"></div>
						</swiper>
					</div>
				</div>
				<div class="pro3_right" @click="palyVideo(production2.video)">
					<div class="pro3_right_pic" :style=" { backgroundImage: 'url(' + bgImage2 + ') ', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }">
						<img src="https://videosite2024.oss-cn-shenzhen.aliyuncs.com/v2/play.jpg" style="width:120px;height:120px;" />
					</div>
				</div>
			</div>
		</div>
		
		<div class="product4" >
			<div class="pro4_content">
				<div class="pro4_top">
					<div class="pro4_top_title">
						<span>
							真实案例展示-建筑表现动画
						</span>
					</div>
					<div class="pro4_top_subtitle">
						<span>
							沟通是成功的桥梁
						</span>						
					</div>
					<div class="pro4_top_memo">
						<span>
							专业技术，值得信赖
						</span>
					</div>
				</div>
				<div class="pro4_bottom">
					<div class="pro4_bottom_swiper">
						<swiper class="swiper" ref="swiper4" :options="swiperOption4">
							<swiper-slide v-for="(tdata,tindex) in pro4DataList" :data-index="tindex" :key="tdata.id">
								<img :src="tdata.img" width="100%" @click="palyVideo(tdata.video)"/>
							</swiper-slide>
						</swiper>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 视频 -->
		<el-dialog
			title="视频预览"
			:visible.sync="dialogPlay"
			width="60%"
			@close="closeDialog"
		>
			<video
				:src="videoUrl"
				controls
				autoplay
				class="video"
				ref="dialogVideo"
				width="100%"
			></video>
		</el-dialog>
	</div>
</template>

<script>
import {data} from "../../assets/js/datav2.js";
import VHeader from "../../components/headerv2.vue";
//页面引入swiper
import 'swiper/dist/css/swiper.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';


export default {
	name: "indexnew",
	components:{
		VHeader,swiper,swiperSlide
	},
	data() {
		const that = this;
		return {
			"title": data.logoSrc,
			videoUrl: "",
			dialogPlay: false,
			/* testDataList: data.testDataList, */
			proDataList: data.proDataList,
			pro2DataList: data.pro2DataList,
			pro4DataList: data.pro4DataList,
			productionIndex_1: 0,
			productionIndex_2: 0,
			productionIndex_4: 0,
			swiperOption1: {
				slidesPerView: 'auto',
				spaceBetween: 30,
				direction: 'horizontal',
				loop: false,
				//小手掌抓取滑动
				grabCursor: true,
				//自动播放
				/* autoplay: {
					delay: 1000,
					disableOnInteraction: false
				}, */
				navigation: {
					nextEl: '.swiper-button-next_1',
					prevEl: '.swiper-button-prev_1'
				},
				//滑动之后回调函数
				on: {
					/* resize: () => {
						this.$refs.swiper1.$swiper.changeDirection(
							window.innerWidth <= 960 ? 'vertical' : 'horizontal'
						);
					}, */
					slideChangeTransitionStart: function () {
						//获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
						// this.showProduction(this.realIndex);
						that.productionIndex_1 = this.realIndex;
					},
				}
			},
			swiperOption2: {
				slidesPerView: 'auto',
				spaceBetween: 30,
				direction: 'horizontal',
				loop: false,
				//小手掌抓取滑动
				grabCursor: true,
				//自动播放
				/* autoplay: {
					delay: 1000,
					disableOnInteraction: false
				}, */
				navigation: {
					nextEl: '.swiper-button-next_2',
					prevEl: '.swiper-button-prev_2'
				},
				on: {
					slideChangeTransitionStart: function () {
						//获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
						// this.showProduction(this.realIndex);
						that.productionIndex_2 = this.realIndex;
					},
				}
			},
			swiperOption4: {
				slidesPerView: 3,
				spaceBetween: 10,
				centeredSlides: true,
				loop: true,
				//小手掌抓取滑动
				grabCursor: true,
				direction: "horizontal",
				height: 520
			},
			
			
		}
	},
	computed:{
		production1(){
			if(!this.proDataList){
				return "";
			}
			if(this.productionIndex_1 && this.proDataList.length <= this.productionIndex_1 ){
				return "";
			}
			return this.proDataList[this.productionIndex_1];
		},
		bgImage1(){
			/* if(!this.testDataList){
				return "";
			}
			if(this.productionIndex_1 && this.testDataList.length <= this.productionIndex_1 ){
				return "";
			} */
			return this.production1?.img;
		},
		production2(){
			console.log(this.productionIndex_2);
			if(!this.pro2DataList){
				return "";
			}
			if(this.productionIndex_2 && this.pro2DataList.length <= this.productionIndex_2 ){
				return "";
			}
			return this.pro2DataList[this.productionIndex_2];
		},
		bgImage2(){
			console.log(this.production2?.img);
			return this.production2?.img;
		},
	},
	created() {
		/* console.log(this.testDataList); */
	},
	methods: {
		jump(url){
			if(!url){
				return;
			}
			if(url.startsWith("/?mount=")){
				// 页面内跳转
				this.toSection(url.substring("/?mount=".length));
			} else {
				this.$router.push(url);
			}
		},
		palyVideo(url){
			this.dialogPlay = true;
			this.videoUrl = url;
		},
		closeDialog() {
			this.dialogPlay = false;
			this.videoUrl = ""; //清空数据 关闭视频播放
		},
		
	}
}
</script>

<style>
@import "../../../src/assets/css/homev2.css";
</style>