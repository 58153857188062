<template>
    <div class="header">
        <div class="header-content">
            <div class="logo">
                <img :src="logoSrc" alt="">
            </div>
            <div class="menu">
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#000">
                    <template v-for="(item,index) in menus">
                        <el-menu-item :index="item.index" :key="index" v-if="!item.subMenues" @click="jump(item.url)">
                            <span slot="title">{{item.name}}</span>
                        </el-menu-item>
                        <el-submenu v-else :index="item.index" :key="item.index" >
                            <template slot="title">{{item.name}}</template>
                            <el-menu-item v-for="(child) in item.subMenues" :index="child.index" :key="child.index"
                                          @click="jump(child.url)">{{child.name}}</el-menu-item>
                        </el-submenu>
                    </template>

                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
import {data} from "../assets/js/datav2";
export default {
    name: "headerv2",
    data() {
        return {
            logoSrc: data.logoSrc,
            activeIndex: "1",
            menus: data.menus
        }
    },
    methods: {
        jump(url){
            /*console.log(url);
            this.$router.push(url);*/
            this.$emit("jumpTo",url);
        },
    }
}
</script>

<style scoped>
.header {
    height: 4.625rem;
    box-shadow: inset 1px 1px 4px rgba(0,0,0,0.15);
}

.header-content {
    width: 87.5rem;
    margin: 0 auto;

    display: flex;
    flex-direction: row;
	justify-content: space-between;
	
	margin-top: 0.625rem;
}

.logo {
    float: left;
}
.logo img {
    max-width: 100%;
    vertical-align: middle;
    outline: 0;
}
.menu {
    margin-right: 3.9375rem;

    display: flex;
    align-items: center;
}

.el-menu.el-menu--horizontal {
    border: 0 !important;
}
.el-menu--horizontal>.el-menu-item{
	overflow-wrap: break-word;
	color: rgba(0, 0, 0, 1);
	font-size: 1rem;
	font-family: PingFangSC-Medium;
	font-weight: 500;
	text-align: left;
	white-space: nowrap;
}
</style>
