<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>

      <div class="main">
          <img :src="aboutSrc" alt="">
      </div>

      <!-- 底部 -->
      <v-footer></v-footer>

  </div>
</template>

<script>

import {data} from "../assets/js/data.js";

import VHeader from "../components/header.vue";
import VFooter from "../components/footer.vue";
export default {
    name: "about",
    components: {
        VHeader,VFooter
    },
    data()
    {
        return {
            aboutSrc: data.aboutSrc
        }
    },
    methods: {
        jump(url){
            this.$router.push(url);
        }
    }
}
</script>

<style scoped>
@import "../../src/assets/css/common.css";
</style>
