<template>
  <div class="page">
      <!-- 头部 -->
      <v-header @jumpTo="jump" id="vheader"></v-header>
	
      <div class="main">
          <img :src="aboutSrc" alt="">
      </div>

  </div>
</template>

<script>

import {data} from "../../assets/js/datav2.js";

import VHeader from "../../components/headerv2.vue";

export default {
    name: "aboutv2",
    components: {
        VHeader
    },
    data()
    {
        return {
            aboutSrc: data.aboutSrc
			//aboutSrc:  require("../../assets/img/v2/workflow.png")
        }
    },
	created(){
		
	},
    methods: {
        jump(url){
            this.$router.push(url);
        }
    }
}
</script>

<style scoped>
@import "../../../src/assets/css/homev2.css";
</style>
<style scoped>

.main img {
	top: -85px;
	margin-top: 1.1875rem;
	margin-bottom: 1.1875rem;
	position: relative;
	z-index: -1;
}

</style>